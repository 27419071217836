<template>
    <div>

        <div class="hero-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-4 custom_animation text-center text-md-start">
                        <img src="../../assets/image/1737127245559710.jpg" alt="დავით კენჭაძე" class="profile-img mb-4">
                    </div>
                    <div class="col-md-8 custom_animation text-center text-md-start">
                        <div class="border-bottom border-2 border-black mb-3">
                            <h1 class="mb-4 title">გახადე ცხოვრება უკეთესი</h1>
                            <p class="lead mb-4">რადგან შენ იმსახურებ მეტს - კარგ სამსახურს, ხარისხიან
                                განათლებას, ფინანსურ
                                თავისუფლებას და უკეთეს სახლს, სადაც გექნება ყველაფერი, რაც ასე ძალიან გინდა.</p>
                        </div>
                        <h5 class="text-center text-md-end title">დავით კენჭაძე</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mb-5">
            <div class="grid-container">
                <div class="row g-2">
                    <div class="col-sm-6 col-xl-4">
                        <a target="_blank" href="https://v.dk.ge/Home">
                            <div class="card card-1 shadow-sm custom_animation">
                                <div class="card-number">01</div>
                                <h3 class="card-title">KENCHADZE.Jobs</h3>
                                <p class="card-text">უბრალოდ კარგი ვაკანსიები</p>
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-6 col-xl-4">
                        <a target="_blank" href="https://academy.dk.ge/">
                            <div class="card card-2 shadow-sm custom_animation">
                                <div class="card-number">02</div>
                                <h3 class="card-title">KENCHADZE.Academy</h3>
                                <p class="card-text">გახდი შენი თავის საუკეთესო ვერსია</p>
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-6 col-xl-4">
                        <a target="_blank" href="https://network.dk.ge/GoldList">
                            <div class="card card-3 shadow-sm custom_animation">
                                <div class="card-number">03</div>
                                <h3 class="card-title">KENCHADZE.Network</h3>
                                <p class="card-text">შენი კარიერული განვითარების აგენტი</p>
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-6 col-xl-4">
                        <a target="_blank" href="https://re.dk.ge/">
                            <div class="card card-4 shadow-sm custom_animation">
                                <div class="card-number">04</div>
                                <h3 class="card-title">KENCHADZE.Real Estate</h3>
                                <p class="card-text">როცა ეძებ უძრავ ქონებას</p>
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-6 col-xl-4">
                        <a target="_blank" href="https://in.dk.ge/Investments">
                            <div class="card card-5 shadow-sm custom_animation">
                                <div class="card-number">05</div>
                                <h3 class="card-title">KENCHADZE.Investments</h3>
                                <p class="card-text">აღმოაჩინე ჩვენი მიმდინარე მშენებლობები</p>
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-6 col-xl-4">
                        <a href="#">
                            <div class="card card-6 shadow-sm custom_animation">
                                <div class="card-number">06</div>
                                <h3 class="card-title">KENCHADZE.Developers soon...</h3>
                                <p class="card-text">როცა არჩევ სამშენებლო კომპანიას</p>
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-6 col-xl-4">
                        <a target="_blank"
                            href="https://open.spotify.com/show/0K5csz9pQ4atb3MvBPjzKX?si=0257768dc8a140a1">
                            <div class="card card-7 shadow-sm custom_animation">
                                <div class="card-number">07</div>
                                <h3 class="card-title">KENCHADZE.Podcast</h3>
                                <p class="card-text">საუბრები ყველაფერზე, რაც ჩვენს ცხოვრებას აუმჯობესებს</p>
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-6 col-xl-4">
                        <a target="_blank" href="https://dkcapital.ge/">
                            <div class="card card-8 shadow-sm custom_animation">
                                <div class="card-number">08</div>
                                <h3 class="card-title">DK CAPITAL</h3>
                                <p class="card-text">როცა ყიდი უძრავ ქონებას</p>
                            </div>
                        </a>
                    </div>

                    <div class="col-sm-6 col-xl-4">
                        <a target="_blank" href="https://linktr.ee/davidkenchadze">
                            <div class="card card-9 shadow-sm custom_animation">
                                <div class="card-number">09</div>
                                <img src="../../assets/icons/linketree_dark.png" alt="linketree" class="w-25 mb-3">
                                <img src="../../assets/icons/linketree_white.png" alt="linketree" class="w-25 mb-3">
                                <h3 class="card-title">KENCHADZE.Group</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
    name: 'Welcome_2',
    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        const elements = document.querySelectorAll(".custom_animation");
        ScrollTrigger.batch(elements, {
            start: "top bottom-=10",
            onEnter: (batch) => {
                gsap.to(batch, {
                    opacity: 1,
                    y: 0,
                    stagger: 0.15,
                    duration: 0.5,
                    ease: "power2.out",
                });
            },
            onLeave: (batch) => {
                gsap.to(batch, {
                    opacity: 0,
                    y: -50,
                    stagger: 0.15,
                    duration: 0.5,
                    ease: "power2.in",
                });
            },
            onEnterBack: (batch) => {
                gsap.to(batch, {
                    opacity: 1,
                    y: 0,
                    stagger: -0.15,
                    duration: 0.5,
                    ease: "power2.out",
                });
            },
            onLeaveBack: (batch) => {
                gsap.to(batch, {
                    opacity: 0,
                    y: 50,
                    stagger: -0.15,
                    duration: 0.5,
                    ease: "power2.in",
                });
            },
        });
        // საწყისი სტილები
        gsap.set(elements, {
            opacity: 0,
            y: 50,
        });

        const currentUrl = window.location.href.toLowerCase();
        const baseUrls = [
            'https://v.dk.ge',
            'https://v.dk.ge/',
            'http://v.dk.ge',
            'http://v.dk.ge/'
        ];

        if (baseUrls.includes(currentUrl)) {
            window.location.href = 'https://v.dk.ge/Home';
        }
    }
}
</script>


<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--bold-font);
    font-feature-settings: "case" on;
}

a {
    text-decoration: none;
}

.hero-section {
    padding: 4rem 0;
    background-color: #f8f9fa;
    margin-bottom: 4rem;
}

.card {
    height: 100%;
    transition: padding 0.3s ease;
    border: 1px solid #eee;
    border-radius: 1rem;
    padding: 4rem 2rem 4rem 2rem;
    background-color: rgb(249, 249, 249);
    color: #333;
    font-family: var(--Third-Font);
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 2rem 2rem 6rem 2rem;
}

.card-1:hover {
    background-color: var(--color-1);
}

.card-2:hover {
    background-color: var(--color-2);
}

.card-3:hover {
    background-color: var(--color-3);
}

.card-4:hover {
    background-color: var(--color-4);
}

.card-5:hover {
    background-color: var(--color-5);
}

.card-6:hover {
    background-color: var(--color-6);
}

.card-7:hover {
    background-color: var(--color-7);
}

.card-8:hover {
    background-color: var(--color-8);
}

.card-9:hover {
    background-color: var(--color-9);
}

.profile-img {
    width: 200px;
    height: 200px;
    border-radius: 1rem;
    object-fit: cover;
}

.lead {
    font-family: var(--Third-Font);
}

.grid-container {
    margin: 2rem 0;
}

.card-number {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: inherit;
    transition: color 0.3s ease;
    font-family: var(--bold-font);
}

.card-1 .card-number {
    color: var(--color-1);
}

.card-2 .card-number {
    color: var(--color-2);
}

.card-3 .card-number {
    color: var(--color-3);
}

.card-4 .card-number {
    color: var(--color-4);
}

.card-5 .card-number {
    color: var(--color-5);
}

.card-6 .card-number {
    color: var(--color-6);
}

.card-7 .card-number {
    color: var(--color-7);
}

.card-8 .card-number {
    color: var(--color-8);
}

/* და ა.შ. */

.card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: inherit;
    transition: color 0.3s ease;
    font-family: var(--bold-font);
}

.card-text {
    font-size: 0.9rem;
    opacity: 0.9;
    color: inherit;
    transition: color 0.3s ease;
}

.custom_animation {
    opacity: 0;
    transform: translateY(50px);
}

.card:hover * {
    color: white;
}

.card-9 img:nth-of-type(2) {
    display: none;
}

.card-9:hover img:nth-of-type(1) {
    display: none;
}

.card-9:hover img:nth-of-type(2) {
    display: block;
}

@media screen and (max-width: 767px) {
    .hero-section {
        padding: 1.5rem 0;
        margin-bottom: 0rem;
    }

    .card {
        padding: 2rem 1.5rem 2rem 1.5rem;
    }

    .card:hover {
        transform: translateY(-5px);
        padding: 2rem 1.5rem 2rem 1.5rem;
    }

    .profile-img {
        width: 150px;
        height: 150px;
    }

    .lead {
        font-size: 0.9rem;
    }

    .title {
        font-size: 1.2rem !important;
    }
}
</style>